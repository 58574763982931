import React, { useContext, useState } from "react";
import Cookies from "js-cookie";
import "./index.css";
import { useNavigate } from "react-router-dom";
import PatientContext from "../../context/PatientContext";
import { MdVisibilityOff } from "react-icons/md";
import { MdVisibility } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import { RiContactsFill, RiLockPasswordFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Login = (props) => {
  const { setLandingPage } = useContext(PatientContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isusernamestatus, setUsernameStatus] = useState(false);
  const [ispasswordstatus, setPasswordStatus] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [visibility, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const loginApiCall = async (userData) => {
    setLoginLoader(true);
    const LOGIN_URL = "https://clinical-server-apis.azurewebsites.net/login";

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(userData),
      redirect: "follow",
    };

    const response = await fetch(LOGIN_URL, requestOptions);
    const responseObj = await response.json();

    if (responseObj.status === "success") {
      Cookies.set("sas_token", responseObj.token_details.sas_token, {
        expires: 1,
        path: "/",
      });
      Cookies.set("jwt_token", responseObj.token_details.auth_token, {
        expires: 1,
        path: "/",
      });
      Cookies.set("login_details", JSON.stringify(responseObj.token_details), {
        expires: 1,
      });

      localStorage.setItem(
        "ContentFromAPI",
        JSON.stringify(responseObj.ContentFromAPI)
      );

      setLoginLoader(false);
      setLandingPage(responseObj.ContentFromAPI);
      props.updateToken(
        responseObj.token_details.auth_token,
        responseObj.token_details.sas_token
      );
      navigate("/");
    }
  };

  const updateUsername = (event) => {
    setUsername(event.target.value);
    setUsernameStatus(false);
  };

  const updatePassword = (event) => {
    setPassword(event.target.value);
    setPasswordStatus(false);
  };

  const ValidateUserDetails = (event) => {
    event.preventDefault();

    const userData = {
      username: username,
      password: password,
    };

    let allInputsFilled = true;
    let newStateValues = {};

    for (let key in userData) {
      if (userData[key] === "") {
        newStateValues["is" + key + "status"] = true;
        allInputsFilled = false;
      }
    }

    if (allInputsFilled) {
      loginApiCall(userData);
    } else {
      setUsernameStatus(newStateValues.isusernamestatus);
      setPasswordStatus(newStateValues.ispasswordstatus);
    }
  };
  const removeErrorMsg = () => {
    setPasswordStatus(false);
    setUsernameStatus(false);
  };

  const showoff = () => {
    setPasswordVisible(!visibility);
  };

  return (
    <div className="login-page-container">
      <form className="login-form-container" onSubmit={ValidateUserDetails}>
        <h1 className="login-heading">Login</h1>
        <div className="label-with-input-container width-100-img">
          <label className="input-label">Username</label>
          <div className="logo-with-input-continaer">
            <RiContactsFill className="auth-icons" />
            <input
              className="input-bar"
              type="text"
              placeholder="username"
              onChange={updateUsername}
              onFocus={removeErrorMsg}
            />
          </div>
          {isusernamestatus && (
            <p className="error-text">Please enter your Username</p>
          )}
        </div>
        <div className="label-with-input-container width-100-img">
          <label className="input-label">Password</label>
          <div className="logo-with-input-continaer">
            <RiLockPasswordFill className="auth-icons" />
            <input
              className="input-bar"
              type={visibility ? "text" : "password"}
              placeholder="Password"
              onChange={updatePassword}
              onFocus={removeErrorMsg}
            />

            {visibility ? (
              <MdVisibility className="password-img" onClick={showoff} />
            ) : (
              <MdVisibilityOff onClick={showoff} className="password-img" />
            )}
          </div>
          {ispasswordstatus && (
            <p className="error-text">Please enter your Password</p>
          )}
        </div>
        <button className="login-button" type="submit">
          {loginLoader ? (
            <div className="login-loader-container">
              <ThreeCircles
                height="30"
                width="30"
                color="white"
                ariaLabel="three-circles-loading"
                visible={true}
              />
            </div>
          ) : (
            "Login"
          )}
        </button>
        <div className="valid-page">
          <span id="vaild-pass"></span>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            className="footer-click login-below-button"
            href="mailto:clinicalailabs@apollohospitals.com"
            style={{ color: "#456d76", fontWeight: "600" }}
          >
            Request Access
          </a>
        </div>
      </form>
      <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
        <button
          className="footer-click privacy-button login-below-button"
          style={{ color: "black", fontWeight: "600", marginTop: "30px" }}
        >
          Privacy Policy
        </button>
      </Link>
    </div>
  );
};

export default Login;
