import React, { useState, useMemo } from "react";
import TableOfContent from "../TableOfContent";
import "./index.css";
import { Link } from "react-router-dom";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Cookies from "js-cookie";

export default function ContentComponent(props) {
  const [isPlaying, setIsPlaying] = useState(true);
  const [popup, setPopup] = useState(false);
  const [currentRef, setRef] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [tokenExpiry, setExpiry] = useState(false);
  const token = Cookies.get("sas_token");

  const handleItemClick = (section) => {
    const indexMap = {
      AICVD: 0,
      Prediabetes: 1,
      AICOPD: 2,
      Liver: 3,
      AICKD: 4,
      EARS: 0,
      EWS: 1,
      Anesthesia: 2,
      CXR: 0,
      TB: 1,
    };

    const index = indexMap[section];
    if (index !== undefined) {
      setRef(index);
      setIsPlaying(false);
      setActiveItem(section);
      setTimeout(() => {
        setIsPlaying(true);
      }, 2000);
    }
  };

  const noAccessPopup = () => {
    setPopup(true);
  };

  const closetoggle = () => {
    setPopup(false);
  };

  const getImageBlobUrl = useMemo(() => {
    const imageMap = new Map(props.images.map((img) => [img.id, img.blobUrl]));
    return (id) => imageMap.get(id) || "";
  }, [props.images]);

  function isTokenExpired(url) {
    try {
      const urlParams = new URL(url).searchParams;
      const expiryTime = urlParams.get("se");

      if (expiryTime) {
        const expiryDate = new Date(expiryTime); // Parse to Date object
        const now = new Date(); // Current time

        return now > expiryDate; // Return true if current time is past expiry
      }

      console.error("The 'se' parameter is missing in the URL.");
      setExpiry(true);
      return true; // Assume expired if 'se' is missing
    } catch (error) {
      console.error("Error parsing URL:", error);
      setExpiry(true);
      return true; // Assume expired in case of errors
    }
  }

  return (
    <div id="" className="front-page-container">
      <div
        className="list-of-scrolling-items"
        onMouseEnter={() => setIsPlaying(false)}
        onMouseLeave={() => setIsPlaying(true)}
      >
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={120}
          totalSlides={props.contentList.length}
          isPlaying={isPlaying}
          interval={5000}
          infinite={true}
          style={{ height: "100%" }}
          className="carousel-provider"
          currentSlide={currentRef}
        >
          <Slider className="slider-block-container">
            {props.contentList.map((content, i) => {
              const imageUrl =
                isTokenExpired(
                  `https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/${content.name}-home-page.jpg?${token}`
                ) || tokenExpiry
                  ? getImageBlobUrl(
                      `home-image-${content.indexName.toLowerCase()}`
                    )
                  : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/${content.name}-home-page.jpg?${token}`;

              return (
                <Slide
                  index={i}
                  key={content.indexName}
                  value={content.indexName}
                  style={{ height: "100%" }}
                >
                  <div className="contentBox" id={content.title + i}>
                    <div className="leftSection">
                      <div
                        className="left-section-content"
                        style={{
                          padding: [
                            "EWS",
                            "TB",
                            "CXR",
                            "AICKD",
                            "EARS",
                          ].includes(content.indexName)
                            ? "30px"
                            : "56px",
                        }}
                      >
                        <h2 className="textTitle animate-slide-down">
                          {content.title}
                        </h2>
                        <p className="textPara animate-slide-down">
                          {content.para}
                        </p>
                        {props.availableApps.includes(
                          content.indexName.toLowerCase()
                        ) ? (
                          <Link
                            to={content.indexName.toLowerCase()}
                            className="link"
                          >
                            <div className="get-started-button-container animate-slide-down">
                              <button className="OpenButton" type="button">
                                Get started
                              </button>
                            </div>
                          </Link>
                        ) : (
                          <div
                            className="get-started-button-container"
                            onClick={noAccessPopup}
                          >
                            <button className="OpenButton">Get started</button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="rightSection">
                      <img
                        src={imageUrl}
                        alt={content.title}
                        className="contentImg-landing"
                      />
                    </div>
                  </div>
                </Slide>
              );
            })}
          </Slider>
        </CarouselProvider>
      </div>
      <TableOfContent
        onItemClick={handleItemClick}
        tableOfContent={props.tableOfContent}
        titleNames={props.titleNames}
        activeItem={activeItem}
      />
      {popup && (
        <div id="popup-container" className="popup-main-container">
          <div className="warring-popup-container small-mb-25">
            <p className="popup-text-highlight-text">Request Access</p>
            <button
              className="close-button info-button"
              type="button"
              onClick={closetoggle}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
