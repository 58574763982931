import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import PatientDetails from "../../common/PatientDetails";
import Attributes from "../../common/Attributes";
import Report from "../../common/Report";
import { FieldsProvider } from "../../../context/FieldsContext";
import AICVDPdfDetails from "../../../components/pdfReports/AICVDPdfReport";
import {
  getImagesFromDB,
  fetchAndStoreImages,
} from "../../../utils/imageUtils";

const AICVDRoutes = (props) => {
  const AICVD_LANDING_URL = "https://clinical-server-apis.azurewebsites.net/fields/aicvd";
  const AICVD_REPORT_URL = "https://clinical-server-apis.azurewebsites.net/aicvd";

  const [formDetails, setFormDetails] = useState({});
  const [PdfInfo, setPDFDetails] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState([]);

  const app = "aicvd";

  const color = "rgb(255, 128, 128)";
  const secondaryColor = "rgb(255, 102, 102)";
  const button_hover_color = "#ff9999";
  const surface_color = "#f2ecee";
  const surface_hover_color = "#e8e0e8";
  const surface_highlight_color = "#dcdaf5";
  const gender_highlight_color = "#675496";
  const button_color = "rgb(123, 104, 171)";
  const surface_highlight_dark_color = "rgb(103, 84, 150)";

  const attribute_values = {
    height: "",
    weight: "",
    bmi: "",
    heart_rate: "",
    systolic_bp: "",
    diastolic_bp: "",
    respiration_rate: "",
    pulse_rhythm: "",
    heart_symptoms: "",
    pyschological_stress: "",
    diet: "",
    smoking: "",
    tobacco: "",
    alcohol: "",
    diabetes: "",
    physical_activity: "",
    hypertension: "",
    dyslipidaemia: "",
    family_heart_disease: "",
    heart_disease: "",
  };

  const form_details = (props) => {
    const details = {
      patient_id: props.patient_id,
      name: props.name,
      age: props.age,
      gender: props.gender,
      location: props.location,
      email: props.email,
      phone: props.phone,
      height: props.height,
      weight: props.weight,
      bmi: props.BMI,
      heart_rate: props.heart_rate,
      systolic_bp: props.systolic_bp,
      diastolic_bp: props.diastolic_bp,
      respiration_rate: props.respiration_rate,
      pulse_rhythm: props.pulse_rhythm,
      heart_symptoms: props.heart_symptoms,
      pyschological_stress: props.pyschological_stress,
      diet: props.diet,
      smoking: props.smoking,
      tobacco: props.tobacco,
      alcohol: props.alcohol,
      physical_activity: props.physical_activity,
      hypertension: props.hypertension,
      dyslipidaemia: props.dyslipidaemia,
      heart_disease: props.heart_disease,
      family_heart_disease: props.family_heart_disease,
      diabetes: props.diabetes,
    };

    const pdf_details = {
      Height: props.height,
      Weight: props.weight,
      Bmi: props.BMI,
      "Heart Rate": props.heart_rate,
      "Systolic Bp": props.systolic_bp,
      "Diastolic Bp": props.diastolic_bp,
      "Respiration Rate": props.respiration_rate,
      "Pulse Rhythm": props.pulse_rhythm,
      "Heart Symptoms": props.heart_symptoms,
      "Pyschological Stress": props.pyschological_stress,
      Diet: props.diet,
      Smoking: props.smoking,
      Tobacco: props.tobacco,
      Alcohol: props.alcohol,
      "Physical Activity": props.physical_activity,
      Hypertension: props.hypertension,
      dyslipidaemia: props.dyslipidaemia,
      "Heart Disease": props.heart_disease,
      "Family Heart Disease": props.family_heart_disease,
      Diabetes: props.diabetes,
    };
    setFormDetails(details);
    setPDFDetails(pdf_details);
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const storedImages = await getImagesFromDB(app);
        if (storedImages.length > 0) {
          setImages(storedImages);
        } else {
          const urls = [
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo-logo.png",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/iStock-644984072_vhiiap_c_scale%252Cw_1400.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/aicvd-app-page-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/aicvd-app-page-2.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/intepretation-img-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo_certificate_MD%20763515.jpg",
          ];
          await fetchAndStoreImages(app, urls, setImages);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };
    loadImages();
  }, []);

  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={
            <AppLandingPage
              LANDING_URL={AICVD_LANDING_URL}
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
              images={images}
              loading={loading}
            />
          }
        />
        <Route
          path="patient-details"
          element={
            <PatientDetails
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
              gender_highlight_color={gender_highlight_color}
              surface_highlight_dark_color={surface_highlight_dark_color}
              button_color={button_color}
            />
          }
        />
        <Route
          path="attributes"
          element={
            <Attributes
              app={app}
              attribute_values={attribute_values}
              form_details={form_details}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          path="report"
          element={
            <Report
              app={app}
              formDetails={formDetails}
              REPORT_URL={AICVD_REPORT_URL}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          app={app}
          path="pdfreport"
          element={
            <AICVDPdfDetails
              formDetails={formDetails}
              PdfInfo={PdfInfo}
              color={color}
            />
          }
        />
      </Routes>
    </FieldsProvider>
  );
};

export default AICVDRoutes;
