import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientContext from "../../../context/PatientContext";
import FieldsContext from "../../../context/FieldsContext";
import OuterFrame from "../OuterFrame";
import Input from "../Input";
import "./index.css";
import PopUpConcent from "../AnyPopUp";
import ConcentForm from "../PaitentConcent";
const PatientDetails = (props) => {
  const navigate = useNavigate();
  const { setPatientDetails } = useContext(PatientContext);
  const { fields } = useContext(FieldsContext);
  const [formErrorStatus, setformErrorStatus] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [patientDetailsForm, setPatientDetailsForm] = useState([]);
  const [current_app, setApp] = useState(props.app);
  const [showConcent, toggleConcent] = useState(false);
  const [patient_consent, setPatientConsent] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  useEffect(() => {
    let formError = {};
    setApp(props.app);
    const savedContent = JSON.parse(localStorage.getItem("fields"));
    const patient_info = fields.Patient_Details
      ? fields.Patient_Details
      : savedContent.Patient_Details;
    setPatientDetailsForm(patient_info);

    patient_info.forEach((field) => {
      formError[field.key] = false;
    });

    setformErrors(formError);

    const patient_consent_info = fields.patient_consent
      ? fields.patient_consent
      : savedContent.patient_consent;
    setPatientConsent(patient_consent_info);
    setApp(props.app);
  }, [fields.Patient_Details, props.app, fields]);

  const handleChange = (key, value) => {
    setPatientDetailsForm((prevDetailsForm) => {
      const updatedForm = prevDetailsForm.map((detail) =>
        detail.key === key ? { ...detail, value } : detail
      );
      validateForm(updatedForm);
      return updatedForm;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonClicked(true);
    if (validateForm(patientDetailsForm)) {
      const formData = patientDetailsForm.reduce((acc, detail) => {
        acc[detail.key] = detail.value;
        return acc;
      }, {});
      setPatientDetails(formData);
      navigate(`/${current_app}/attributes`);
    }
  };
  const validateForm = (updatedForm) => {
    let hasError = false;
    let errors = {};
    updatedForm.forEach((patient) => {
      if (patient.required === "yes" && !patient.value) {
        hasError = true;
        errors[patient.key] = true;
      } else {
        errors[patient.key] = false;
      }
    });
    setformErrors(errors);
    setformErrorStatus(hasError);

    return !hasError;
  };

  const handleCheckboxChange = (checkboxId) => {
    if (checkboxId === 1) {
      setIsChecked1((prev) => !prev);
    } else if (checkboxId === 2) {
      setIsChecked2((prev) => !prev);
    }
  };

  const updateFormErrors = (fieldName, value) => {
    setformErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: value,
    }));
  };


  return (
    <OuterFrame
      appName={fields.landing_page[0].heading}
      hightlight="1"
      color={props.color}
      secondaryColor={props.secondaryColor}
      surface_color={props.surface_color}
      surface_highlight_color={props.surface_highlight_color}
      surface_hover_color={props.surface_hover_color}
      button_hover_color={props.button_hover_color}
      component={
        <>
          <div id="contentContainer">
            <form onSubmit={handleSubmit} className="ActualForm">
              <div className="InputForm">
                {patientDetailsForm.map((patient) => (
                  <React.Fragment key={patient.key}>
                    <Input
                      name={patient.key}
                      placeholder={patient.name}
                      type={patient.type}
                      options={patient.options || []}
                      value={patient.value}
                      onChange={handleChange}
                      formErrors={formErrors}
                      label={patient.name}
                      id={`${patient.key}_${patient.name}`}
                      patient_required={patient.required}
                      buttonClicked={buttonClicked}
                      img={patient.img}
                      color={props.gender_highlight_color}
                      updateFormErrors={updateFormErrors}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="showConcentDiv">
                <span
                  className="DocLogoForConcentButton"
                  onClick={() => toggleConcent(true)}
                >
                  <img
                    src="doc-icon.svg"
                    className="consent-icon"
                    alt="consent-icon"
                  />
                </span>
                <button
                  className="showConcentButton"
                  onClick={() => toggleConcent(true)}
                  type="button"
                >
                  PATIENT INFORMATION SHEET AND INFORMED CONSENT
                </button>
              </div>
              <div className="checkBoxForTC">
                <div className="checkbox-for-patient-consent">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id="loginCheckBox1"
                      checked={isChecked1}
                      onChange={() => handleCheckboxChange(1)}
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                  </div>
                  <label
                    htmlFor="loginCheckBox1"
                    className="patient-consent-label-content"
                  >
                    Patient has confirmed that he/she has read and understood
                    the information sheet for the above Risk Scores and have had
                    the opportunity to ask questions
                  </label>
                </div>
                <div className="checkbox-for-patient-consent">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id="loginCheckBox2"
                      checked={isChecked2}
                      onChange={() => handleCheckboxChange(2)}
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                  </div>
                  <label
                    htmlFor="loginCheckBox2"
                    className="patient-consent-label-content"
                  >
                    Patient has agreed to take part in the above NCD Risk Scores
                    in Apollo Hospitals.
                  </label>
                </div>
              </div>
              {formErrorStatus && buttonClicked && (
                <p className="form-validation-error-text">
                  Please enter all required Fields
                </p>
              )}
              <button
                className="nextButton"
                type="submit"
                style={{
                  backgroundColor: buttonHover
                    ? "rgb(123, 104, 171)"
                    : "rgb(103, 84, 150)",
                }}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
              >
                Next
              </button>
            </form>
          </div>
          <PopUpConcent
            showPopUp={showConcent}
            togglePopUp={() => {
              toggleConcent(!showConcent);
            }}
            ContentToDisplayInPopUp={
              <div className="PopUpForPaitentConcent">
                <ConcentForm data={patient_consent} />
              </div>
            }
          />
        </>
      }
    />
  );
};

export default PatientDetails;
