import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import { FieldsProvider } from "../../../context/FieldsContext";
import PatientDetails from "../../common/PatientDetails";
import HepatitisCheckboxForm from "../../test";
import {
  getImagesFromDB,
  fetchAndStoreImages,
} from "../../../utils/imageUtils";

const COPDRoutes = (props) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const app = "aicopd";
  const COPD_LANDING_URL =
    "https://clinical-server-apis.azurewebsites.net/fields/aicopd";
  const color = "#5fa8d3";
  const secondaryColor = " #73b3d9";
  const button_hover_color = "#afd4e9";
  const surface_color = "#fbf3fb";
  const surface_hover_color = "#e8e0e8";
  const surface_highlight_color = "#dcdaf5";

  useEffect(() => {
    const loadImages = async () => {
      try {
        const storedImages = await getImagesFromDB(app);
        if (storedImages.length > 0) {
          setImages(storedImages);
        } else {
          const urls = [
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo-logo.png",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/banner.png",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/copd-app-page-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/copd-app-page-2.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/intepretation-img-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo_certificate_MD%20763515.jpg",
          ];
          await fetchAndStoreImages(app, urls, setImages);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    loadImages();
  }, []);

  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={
            <AppLandingPage
              LANDING_URL={COPD_LANDING_URL}
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              secondaryColor={secondaryColor}
              images={images}
              loading={loading}
              button_hover_color={button_hover_color}
            />
          }
        />
        <Route
          path="patient-details"
          element={
            <PatientDetails
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route path="/test" element={<HepatitisCheckboxForm />} />
      </Routes>
    </FieldsProvider>
  );
};

export default COPDRoutes;
