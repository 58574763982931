import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import PatientDetails from "../../common/PatientDetails";
import Attributes from "../../common/Attributes";
import Report from "../../common/Report";
import { FieldsProvider } from "../../../context/FieldsContext";
import PrediabetesPatientReport from "../../pdfReports/PrediabetesPdfReport";
import {
  getImagesFromDB,
  fetchAndStoreImages,
} from "../../../utils/imageUtils";

const PrediabetesRoutes = (props) => {
  // const PREDIABETES_LANDING_URL = process.env.REACT_APP_PREDIABETES_LANDING_URL;
  // const PREDIABETES_REPORT_URL = process.env.REACT_APP_PREDIABETES_REPORT_URL;

  const PREDIABETES_LANDING_URL =
    "https://clinical-server-apis.azurewebsites.net/fields/prediabetes";
  const PREDIABETES_REPORT_URL =
    "https://clinical-server-apis.azurewebsites.net/prediabetes";

  const app = "prediabetes";

  const [formDetails, setFormDetails] = useState({});
  const [PdfInfo, setPDFDetails] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const color = "#f77f00";
  const secondaryColor = "#ff901a";
  const button_hover_color = "#ff9c33";

  const surface_color = "rgb(254 247 239)";
  const surface_hover_color = "#e8e0e8";
  const surface_highlight_color = "#dcdaf5";

  const attribute_values = {
    alcohol: "",
    alcohol_sub: "",
    bmi: "",
    currentweight: "",
    diet: "",
    dyslipidemia: "",
    dyslipidemia_list: [],
    familyHistory: "",
    family_history_list: [],
    height: "",
    hypertension: "",
    hypertension_list: [],
    hypertension_second_list: [],
    hypertension_third_list: [],
    medical_history: "",
    medical_history_list: [],
    medical_second_history_list: [],
    physical_activity: "",
    symptoms: "",
    symptoms_list: [],
    waist: "",
    weight: "",
  };

  const form_details = (props) => {
    const details = {
      patient_id: props.patient_id,
      name: props.name,
      age: props.age,
      gender: props.gender,
      location: props.location,
      email: props.email,
      phone: props.phone,
      alcohol: props.alcohol,
      alcohol_sub: props.alcohol_sub,
      bmi: parseFloat(props.BMI),
      change_in_weight: props.currentweight,
      diet: props.diet,
      dyslipidaemia: props.dyslipidemia,
      dyslipidemia_list: props.dyslipidemia_list,
      history: props.familyHistory,
      family_history_list: props.family_history_list,
      height: parseInt(props.height),
      hypertension: props.hypertension,
      hypertension_list: props.hypertension_list,
      hypertension_second_list: props.hypertension_second_list,
      hypertension_third_list: props.hypertension_third_list,
      medical_history: props.medical_history,
      medical_history_list: props.medical_history_list,
      medical_second_history_list: props.medical_second_history_list,
      physical_activity: props.physical_activity,
      symptoms: props.symptoms,
      symptoms_list: props.symptoms_list,
      waist: props.waist,
      weight: parseInt(props.weight),
    };

    const pdf_details = {
      Alcohol: props.alcohol,
      Bmi: parseFloat(props.BMI),
      "Change in Weight": props.currentweight,
      diet: props.diet,
      dyslipidaemia: props.dyslipidemia,
      "Family hHistory": props.familyHistory,
      Hight: parseInt(props.height),
      hypertension: props.hypertension,
      "Medical History": props.medical_history,
      "Physical Activity": props.physical_activity,
      Symptoms: props.symptoms,
      Waist: props.waist,
      Weight: parseInt(props.weight),
    };
    setFormDetails(details);
    setPDFDetails(pdf_details);
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const storedImages = await getImagesFromDB(app);
        if (storedImages.length > 0) {
          setImages(storedImages);
        } else {
          const urls = [
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo-logo.png",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/iStock-1296077052-min.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/prediabetes-app-page-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/prediabetes-app-page-2.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/intepretation-img-3.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-ai-images/apollo_certificate_MD%20763515.jpg",
          ];
          await fetchAndStoreImages(app, urls, setImages);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    loadImages();
  }, []);

  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={
            <AppLandingPage
              LANDING_URL={PREDIABETES_LANDING_URL}
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              secondaryColor={secondaryColor}
              images={images}
              loading={loading}
              button_hover_color={button_hover_color}
            />
          }
        />
        <Route
          path="patient-details"
          element={
            <PatientDetails
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          path="attributes"
          element={
            <Attributes
              app={app}
              attribute_values={attribute_values}
              form_details={form_details}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          path="report"
          element={
            <Report
              app={app}
              formDetails={formDetails}
              REPORT_URL={PREDIABETES_REPORT_URL}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          path="pdfreport"
          element={
            <PrediabetesPatientReport
              app={app}
              formDetails={formDetails}
              PdfInfo={PdfInfo}
            />
          }
        />
      </Routes>
    </FieldsProvider>
  );
};

export default PrediabetesRoutes;
