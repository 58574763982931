import React from "react";

const PrivacyPolicy = () => {

  return (
    <>
      <div id="privacyPolicyBox" className="app-popup-main-container">
        <div className="app-popup-container">
          <h1 className="app-popup-content-heading">Privacy Policy</h1>
          <div className="app-popup-content-container">
            <p className="app-popup-text-highlight-text">
              Please read this Privacy Policy carefully.
            </p>
            <ul className="app-popup-list-container">
              <li>
                This Privacy Policy applies to the website and its associated
                pages, features, content, and any services offered in connection
                with it (collectively referred to as the "Website"). The Website
                is published and maintained by its owner (hereinafter referred
                to as "we," "us," or "our").
              </li>
              <li>
                This Privacy Policy, as amended from time to time, is a part of
                the terms and conditions (the "Terms") governing the use of the
                Website and is incorporated into the Terms by reference. This
                Privacy Policy does not apply to the websites of business
                partners, affiliates, or any other third parties, even if their
                websites are linked to this Website. It is recommended to review
                the respective privacy statements of such third parties
                independently of this Privacy Policy.
              </li>
              <li>
                Capitalized terms used in this Privacy Policy but not defined
                herein shall have the meaning attributed to them in the Terms.
                In case of any inconsistency between the Terms and this Privacy
                Policy, the provisions of this Privacy Policy shall prevail
                solely in respect of the content herein and not for any other
                clause, term, or provision in the Terms.
              </li>
              <li>
                By accessing the Website or otherwise using the Website, you
                (hereinafter referred to as “You”, “Your”, “User”, as
                applicable) expressly consent to Our collection, storage, use,
                and disclosure of the Personal Information (as defined
                hereunder) in accordance with the terms of this Privacy Policy.
                You unconditionally signify Your
                <p>(i) assent to the Privacy Policy,</p>
                <p>
                  (ii) consent to the utilization of your Personal Information
                  in accordance with the provisions of this Privacy Policy.
                </p>
              </li>
              <li>
                As We update, improve, and expand the Website, this Privacy
                Policy may change at Our sole discretion without notice, so
                please refer back to it periodically as You will be deemed to be
                governed by the terms of such modified Privacy Policy, as it
                comes into effect from time to time. By using Our Website, you
                are deemed to have read, acknowledged, and accepted all the
                provisions stated herein, including any changes that we may make
                from time to time.
              </li>
              <p className="app-popup-text-highlight-text">
                TYPE OF INFORMATION COLLECTED
              </p>
              <ul className="app-popup-list-container">
                <li>
                  Our primary goal is to provide You with a safe, efficient,
                  smooth, and customized experience. This allows Us to provide
                  Content and features and to customize the Website to make Your
                  experience safer and easier. More importantly, while doing so,
                  We collect Personal Information, non-personal information, and
                  other information and correspondences from You, as detailed
                  below, that We consider necessary for achieving this purpose
                  (such information and correspondences hereinafter collectively
                  referred to as “Information”). Further, You hereby acknowledge
                  that the utilization of Your Personal Information by the
                  Company is necessary for the purposes identified hereunder.
                  You hereby consent that the utilization of any Personal
                  Information in accordance with the provisions of this Privacy
                  Policy shall not cause any wrongful loss to You.
                </li>
                <li>
                  You have the option not to provide us with the Personal
                  Information sought to be collected. You will also have an
                  option to withdraw your consent at any point, provided such
                  withdrawal of the consent is intimated to us in writing.
                  Provided however that, if You do not provide Us Your Personal
                  Information or if You withdraw the consent to provide Us Your
                  Personal Information at any point in time, We shall have the
                  option not to fulfill the purposes for which the said Personal
                  Information was sought and We may restrict You from using the
                  Website or Content:
                </li>
                <ul className="app-popup-list-container">
                  <li>
                    <strong>Personal Information:</strong> Personal Information
                    may include without limitations, all information that can be
                    linked to a specific individual or to identify any
                    individual, such as your name, address, phone, date of
                    birth, social security information, email, third-party
                    application IDs, and other similar information (“Personal
                    Information”). Please note that the above list is not an
                    exhaustive list and is only an illustrative list and is
                    without prejudice to Our right to seek further Personal
                    Information, as may be required for the provision of the
                    Content to You. Note: Any content on the Website shared by
                    You or end users via reviews, blogs, feedback, online chat
                    or instant messages, discussion boards, forums, user
                    testimonials, etc. is not considered Personal Information
                    and is not subject to the terms of this Privacy Policy. We
                    will not be responsible for the accuracy or filtration of
                    any of the content posted on Our Website by You or any end
                    user, however, We will be entitled to remove any content
                    posted by any person on Our Website which We, in Our sole
                    discretion, consider offensive, discriminatory, racist,
                    defamatory or inappropriate in nature. When browsing our
                    Website, you are not required to provide any Personal
                    Information unless and until you choose to obtain our
                    Content as available on the Website. You may, however, visit
                    our Website anonymously.
                  </li>
                  <li>
                    <strong>Non-Personal Information:</strong>  When you visit
                    the Website, we may collect certain non-personal information
                    such as your internet protocol address, operating system,
                    browser type, internet service provider, information about
                    the pages on the Website you visit/access, the links you
                    click on the Website, the number of times you access the
                    page and any such browsing information. This type of
                    information does not identify you personally. We may also
                    collect information about the Content that you consume and
                    how you use it, including log-in information and location
                    information, when you are a consumer of the Content through
                    the Website.
                  </li>
                  <li>
                    <strong>Automatic Information: </strong>To improve the
                    responsiveness of the Website for You, We may use “cookies”,
                    or similar electronic tools to collect information to
                    understand the User’s individual interests. These server
                    logs may include information such as Your web request,
                    internet protocol address, browser type, browser language,
                    the date and time of Your request, and one or more cookies
                    that may uniquely identify Your browser. We use cookies
                    primarily for user authentication but may also use them to
                    improve the quality of our Content and/or Website by storing
                    user preferences and tracking user trends
                  </li>
                  <li>
                    <strong> Mobile:</strong> When You use the Website through
                    Your mobile, We may receive information about Your location
                    and Your mobile device, including a unique identifier number
                    for Your device. We may use this information to provide You
                    with suggestions in respect of the Content if required.
                  </li>
                  <li>
                    <strong>User communications: </strong>When You send emails
                    or other communications to Us, we may retain those
                    communications in order to process Your inquiries, respond
                    to Your requests, and improve our content provided through
                    the Website.
                  </li>
                </ul>
              </ul>
            </ul>
            <p className="app-popup-text-highlight-text">
              USE OF YOUR INFORMATION
            </p>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              We may process the Information you provide in any one or more of
              the following manners, in accordance with this Privacy Policy and
              as permitted by applicable laws collectively referred to as
              “Purpose(s)”:
            </p>
            <ul className="app-popup-list-container">
              <li>To personalize your experience; </li>
              <li>
                For providing Content as set out in the Website, We may disclose
                Your Personal Information to the Company’s and/or other persons
                engaged by us (as applicable);
              </li>
              <li>To enhance our Content and the Website; </li>
              <li>To enforce our Terms; </li>
              <li>
                To evaluate of our existing Content and / or develop new
                content;
              </li>
              <li>
                To contact You and to customize Our communication with You and
                the marketing material we share with You;
              </li>
              <li>
                Conducting surveys and collecting feedback about Our Content and
                / or Website;
              </li>
              <li>
                To serve various promotional and advertising materials to You
                via display advertisements through the Google advertisement
                network on third party websites, as applicable. You can opt out
                of Google analytics for display advertising and customize Google
                display network ads using the ads preferences manager;
              </li>
              <li>
                The email address that You may provide in the contact form
                available in the Website, may be used to send You information
                and updates, in addition to receiving occasional company news,
                updates, related product or service information, etc.;
              </li>
              <li>
                Preventing, detecting, investigating and taking action against
                crimes (including but not limited to fraud and other financial
                crimes), any other illegal activities, suspected fraud, or
                violations of Terms in any jurisdiction;
              </li>
              <li>
                To the extent required for identity verification, government
                sanctions screening and due diligence checks;
              </li>
              <li>
                To comply with existing applicable laws including conducting a
                credit check;
              </li>
              <li>
                To protect the security or integrity of the Website, Our
                business, Content and Users;
              </li>
              <li>
                To publish any testimonials or reviews that You may have
                provided on the Website;
              </li>
              <li>
                Conducting surveys and collecting feedback about our Content and
                / or Our Website;
              </li>
              <li>
                To perform academic/studies, clinical or other research and
                analysis for our understanding, information, analysis, services
                and technologies in order to provide all users improved quality
                of care and ensuring that the content and advertising displayed
                are customized to your interests and preferences; and
              </li>
              <li>
                To aggregate Personal Information for research for
                academic/studies, clinical or other research, statistical
                analysis and business intelligence purposes, and to sell or
                otherwise transfer such research, statistical or intelligence
                data in an aggregated and/or non-personally identifiable form to
                third parties and affiliates with a purpose of providing
                services to the users or for the advancement of scientific
                knowledge about health and disease.
              </li>
            </ul>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "1opx",
                marginTop: "10px",
              }}
            >
              You accept, acknowledge and agree that the Company shall be
              entitled to process the Information in accordance with this
              Privacy Policy and as permitted by the applicable law;
            </p>
            <p className="app-popup-text-highlight-text">
              DISCLOSURE OF INFORMATION OBTAINED
            </p>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "1opx",
                marginTop: "10px",
              }}
            >
              You provide Your explicit consent in respect of the following
              events:
            </p>
            <ul className="app-popup-list-container">
              <li>
                We will not use your information for any purpose other than to
                complete a transaction with you
              </li>
              <li>
                Except as expressly stated herein, We do not sell, trade, or
                otherwise transfer to outside parties Your Information. This
                does not include trusted third parties who assist Us in
                operating Our Website, conducting Our business, or servicing
                You. We may also release Your information when We believe
                release is appropriate to comply with the applicable laws,
                enforce Our Website policies, or protect Ours or others rights,
                property, or safety. However, non-personally identifiable
                visitor information may be provided to other parties for
                marketing, advertising, or other uses.
              </li>
              <li>
                We may disclose Information, in good faith, wherein the access,
                use, preservation or disclosure of such Information, including
                preservation of transaction history is reasonably necessary to
                (i) satisfy any applicable law, regulation, legal process or
                enforceable governmental request; (ii) enforce applicable Terms,
                including investigation of potential violations thereof; (iii)
                detect, prevent, or otherwise address fraud, security or
                technical issues; (iv) protect against imminent harm to our
                rights, property or safety, our users or the public as required
                or permitted by law; or (v) to defend against legal claims;
                special circumstances such as compliance with subpoenas, court
                orders, requests/order from legal authorities or law enforcement
                agencies requiring such disclosure.
              </li>
              <li>
                Additionally, in the event of an audit, reorganization, merger,
                sale, joint venture, assignment, transfer or other disposition
                of all or any portion of our business, assets or stock
                (including in connection with any bankruptcy or similar
                proceedings), we may share/transfer/assign the Information we
                have collected to the relevant third party.
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">
              Handling Customer Information for Azure Marketplace SaaS Offers{" "}
            </p>
            <ul className="app-popup-list-container">
              <li>
                For the SaaS offers available on the Azure Marketplace, customer
                information is collected and processed in accordance with
                Microsoft's Marketplace policies and relevant data protection
                regulations. This data is used exclusively to deliver and
                improve the services offered and is never shared with third
                parties without prior consent. We implement robust encryption
                and security measures to protect customer information, ensuring
                that it remains confidential and secure at all times. Our
                commitment to transparency and adherence to the highest
                standards of data privacy is unwavering.{" "}
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">DATA RETENTION</p>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "1opx",
                marginTop: "10px",
              }}
            >
              We shall be entitled to retain Your Information for Our record for
              as long as needed or permitted in light of the Purpose(s) for
              which it was obtained and consistent with applicable law and such
              Information shall be used by Us only in consonance with this
              Privacy Policy. The criteria for determining the duration for
              which We will keep Your Personal Information are as follows:
            </p>
            <ul className="app-popup-list-container">
              <li>
                We will retain copies of Your Personal Information in a form
                that permits identification only for as long as is necessary in
                connection with the purposes set out in this Privacy Policy,
                including for the length of time We have an ongoing relationship
                with You and provide the Website to You;
              </li>
              <li>
                if there is a legal obligation to which We are subject (for
                example, certain laws require Us to keep records of Your
                transactions for a certain period of time before We can delete
                them); or
              </li>
              <li>
                keeping in view the legitimate interests of the Company
                (litigation or regulatory investigations).
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">SECURITY</p>
            <ul className="app-popup-list-container">
              <li>
                The security of Your Personal Information is important to Us. We
                have adopted reasonable security practices and procedures
                including role-based access, secure communication, password
                protection, encryption, etc. to ensure that the Personal
                Information collected is secure. You agree that such measures
                are secure and adequate. We restrict access to Your Personal
                Information to Our and Our affiliates’ employees, agents,
                third-party service providers, partners, and agencies who need
                to know such Personal Information in relation to the Purposes
                specified above in this Privacy Policy, provided that such
                entities agree to abide by this Privacy Policy.{" "}
              </li>
              <li>
                We use reasonable security measures to protect Information from
                unauthorized access, maintain data accuracy, and help ensure the
                appropriate use of Information. When the Content on Our Website
                is accessed using Internet Explorer, Firefox, Safari, or Chrome
                or any Secure Socket Layer, or such technology that may protect
                Information using both server authentication and data
                encryption. We host Our Website in a secure server environment
                that uses firewalls and other advanced technology to prevent
                interference or access from outside intruders.
              </li>
              <li>
                You are responsible for maintaining the security and
                confidentiality of Your user IDs and passwords while accessing
                the Website as a User through Your User account (“User
                Account”). You acknowledge that neither the Company nor any of
                its directors, affiliates, shareholders, or other
                representatives shall be liable to You under any circumstances
                for any direct, indirect, punitive, incidental, special, or
                consequential damages that result from or arise out of, or a
                breach or, or compromise of registration of Your User Account
                with us, You loss of Your user id and password in respect of
                Your User Account and/or Your ability or inability to use the
                User Account.
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">COOKIES</p>
            <ul className="app-popup-list-container">
              <li>
                The Company uses cookies to personalize Your experience on the
                Website and to display advertisements or send you emails of
                offers of Your interest (provided you have not opted out of
                receiving such emails)). Cookies are small pieces of information
                that are stored by Your browser on Your device's hard drive.
                Cookies allow You to easily access our Website, by logging You
                in without having to type your login name each time.
              </li>
              <li>
                By using this Website You agree that We can place cookies on
                Your computer or device as explained above. However, You can
                control and manage cookies in various ways. Removing or blocking
                cookies can impact user experience and parts of the Website may
                no longer be fully accessible.{" "}
              </li>
              <li>
                Most browsers will allow You to delete them on an individual
                basis or block cookies from particular or all websites. In the
                event of You deleting all cookies, you will lose any and all
                preferences You have set including Your preference to opt out
                from cookies.
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">
              LINKS TO THIRD PARTY SITES
            </p>
            <ul className="app-popup-list-container">
              <li>
                The Website may include links to third-party
                applications/websites (“Third Party Sites”). Such Third Party
                Sites are governed by their respective privacy policies, which
                are beyond Our control. Once You leave Our Website, the use of
                any information You provide is governed by the privacy policy of
                the operator of the Third Party Site You are visiting. That
                policy may differ from Ours. If You can't find the privacy
                policy of any of these Third Party Sites, You should contact the
                relevant Third Party Site directly for more information.{" "}
              </li>
              <li>
                We do not provide any Personal Information or personally
                identifiable information to Third Party Sites/third-party
                websites/advertisers/ad-servers without Your consent.
              </li>
            </ul>
            <p className="app-popup-text-highlight-text">YOUR CONSENT</p>
            <ul className="app-popup-list-container">
              <li>
                By using the Website and/ or by providing Your Information, Your
                consent to the collection and use of such Information in
                accordance with this Privacy Policy, including but not limited
                to Your consent for sharing Your Information as per this Privacy
                Policy. You specifically agree and consent to us collecting,
                storing, processing, transferring, and sharing Information
                (including Personal Information) related to You with third
                parties, service professionals, or to registered vendors/users
                for the purposes as set out in this Privacy Policy.
              </li>
              <li>
                You may withdraw Your consent to submit any or all Personal
                Information or decline to provide any permissions on the Website
                as covered above at any time. In case, You choose to do so, then
                Your access to the Website may be limited.
              </li>
            </ul>
            <h2
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Thank you for using the Website!
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
