import React from 'react';

const CheckboxInput = ({ option, isChecked, onChange }) => (
  <label>
    <input
      type="checkbox"
      checked={isChecked}
      onChange={() => onChange(option)}
    />
    {option.name}
  </label>
);

export default CheckboxInput;
