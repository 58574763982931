import React, { useEffect, useState } from "react";
import "./index.css";

const PrediabetesPatientReport = (props) => {
  const [apidata, setAPI] = useState();

  useEffect(() => {
    const report = props.data.data;
    setAPI({
      activity: report.activity,
      nutrition: report.nutrition,
      referralToConsultant: report.referral_to_consultant,
      risk: report.risk,
      score: report.score,
      testsFollowUp: report.tests_followup,
      treatmentGoals: report.treatment_goal,
      labInvestigation: report.lab_investigation,
    });
  }, [props]);

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  return (
    <div>
      {apidata && (
        <>
          <div className="report-form-container">
            <div className="">
              <div>
                <div className="report-heading-container">
                  <h1 className="report-sub-heading">PATIENT RISK SCORE</h1>
                </div>
                <div className="risk-box-container">
                  <div className="each-risk-box">
                    <p className="score-box-heading">Risk Status</p>
                    <div className={` ${riskCardColor} score-box`}>
                      {apidata.risk}
                    </div>
                  </div>
                  <div className="each-risk-box risk-box-container-margin">
                    <p className="score-box-heading">Patient Score</p>
                    <div className="score-box font-color">{apidata.score}</div>
                  </div>
                </div>
                <p className="font-size">
                  Note:{" "}
                  <span className="">
                    The risk status is computed with the Machine Learning
                    Algorithm and categorised based on the individual’s chances
                    of Prediabetes / Diabetes. It also provides a score based on
                    the probability of the risk status.
                  </span>
                </p>
              </div>
            </div>
            <div className="">
              <div className="report-heading-container">
                <h1 className="report-sub-heading">RECOMMENDED PROTOCOL</h1>
              </div>
              <p className="report-recommended-list-item-heading">
                LAB INVESTIGATION
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.labInvestigation}
              </p>
              <p className="report-recommended-list-item-heading">Activity </p>
              <p className="report-recommended-list-item-value">
                {apidata.activity}
              </p>
              <p className="report-recommended-list-item-heading">Nutrition</p>
              <p className="report-recommended-list-item-value">
                {apidata.nutrition}
              </p>
              <p className="report-recommended-list-item-heading">
                Referral to consultant
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.referralToConsultant}
              </p>
              <p className="report-recommended-list-item-heading">
                TREATMENT GOALS
              </p>
              <p className="report-recommended-list-item-value">
                {apidata.treatmentGoals}
              </p>
              {apidata.advice && (
                <>
                  <p className="report-recommended-list-item-heading">ADVICE</p>
                  <p className="report-recommended-list-item-value">
                    {apidata.advice}
                  </p>
                </>
              )}
            </div>
            <h1 className="report-recommended-list-item-heading">DISCLAIMER</h1>

            <ul className="report-recommended-list-item-value">
              <li className="disclaimer-list-item">
                This is not a diagnostic tool and it does not guarantee the
                accuracy of the result and cannot be independently acted upon.
              </li>
              <li className="disclaimer-list-item">
                This Risk score and Clinical Algorithm is a general guideline
                for Physicians. Any additional laboratory investigations,
                Diagnostic Imaging, Treatment or Patient Education related to
                lifestyle management is under Physician’s or Endocrinologist’s
                discretion.
              </li>
              <li className="disclaimer-list-item">
                To ensure the information in the report is up to date, accurate
                and correct, Doctor shall be consulted for interpretation of the
                report.
              </li>
              <li className="disclaimer-list-item">
                Apollo Hospitals and its Staff neither offers any assurance on
                the information made available nor will be liable for any loss
                or damage as the said report is based on the Prediabetes Risk
                Score without any intervention from their side.
              </li>
              <li className="disclaimer-list-item">
                By usage of Prediabetes Risk Score, it is deemed that
                beneficiary of this service has agreed to get the same done at
                his own risk and further agrees with this disclaimer without any
                limitation or any clauses or sub-clauses.
              </li>
              <li className="disclaimer-list-item">
                Note on Uncontrolled / Gestational Diabetes and PCOD - The
                current model doesn’t account for (Exclusion Criteria) –
              </li>
              <ul className="report-recommended-list-item-value">
                <li className="disclaimer-list-item">
                  Uncontrolled and Undiagnosed Diabetes
                </li>
                <li className="disclaimer-list-item">Gestational Diabetes</li>
                <li className="disclaimer-list-item">
                  Polycystic Ovarian Disease (PCOD)
                </li>
                <li className="disclaimer-list-item">
                  Previously diagnosed and under treatment Diabetes mellitus
                </li>
              </ul>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default PrediabetesPatientReport;
