// /src/components/common/Attributes.js
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FieldsContext from "../../../context/FieldsContext";
import Input from "../Input";
import { Oval } from "react-loader-spinner"; // Import the loader
import PatientContext from "../../../context/PatientContext";
import OuterFrame from "../OuterFrame";
import SidebarOverlay from "../../Sidebar";

import "./index.css";
const Attributes = (props) => {
  const navigate = useNavigate();
  const { fields } = useContext(FieldsContext);
  const [current_app, setApp] = useState(props.app);
  const [loaderstatus, setLoaderStatus] = useState(true);
  const { attribute_values, setAttributeValues } = useContext(PatientContext);
  const [selectedOption, setSelectedOptions] = useState({});
  const [attributes, setAttributes] = useState([]);
  const { patientDetails } = useContext(PatientContext);
  const [formErrorStatus, setformErrorStatus] = useState(false);
  const [formErrors, setformErrors] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [buttonHover, setButtonHover] = useState(false);

  const toggleSidebar = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  useEffect(() => {
    setApp(props.app);
    const savedContent = JSON.parse(localStorage.getItem("fields"));
    const patient_attributes = fields.Attributes
      ? fields.Attributes
      : savedContent.Attributes;
    setAttributes(patient_attributes);
    props.attribute_values && setAttributeValues(props.attribute_values);
    setLoaderStatus(false);
  }, [
    props.attribute_values,
    fields.Attributes,
    setAttributes,
    props.app,
    setAttributeValues,
  ]);

  const calculateBMI = useCallback((height, weight) => {
    if (height && weight) {
      let bmi = weight / Math.pow(height * 0.01, 2);
      let decimal = bmi - Math.floor(bmi);
      bmi = Math.floor(bmi) + Math.floor(decimal * 100) / 100;
      return bmi;
    }
    return null;
  }, []);

  useEffect(() => {
    const height = attribute_values["height"];
    const weight = attribute_values["weight"];

    const bmi = calculateBMI(height, weight);
    setAttributeValues((prevValues) => {
      if (prevValues.BMI !== bmi) {
        return {
          ...prevValues,
          BMI: bmi,
        };
      }
      return prevValues;
    });
  }, [attribute_values, calculateBMI, setAttributeValues]);

  const handleChange = (key, value) => {
    setAttributeValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [key]: value,
      };
      validateForm(updatedValues);
      return updatedValues;
    });
  };

  const handleMainOptionChange = (key, value) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [key]: value,
      };
      return updatedOptions;
    });

    setAttributeValues((prevAttributeValues) => {
      const updatedValues = {
        ...prevAttributeValues,
        [key]: value,
      };
      validateForm(updatedValues);
      return updatedValues;
    });
  };

  const handleCheckboxChange = (key, value) => {
    setAttributeValues((prevAttributeValues) => {
      const currentValues = prevAttributeValues[key] || [];
      let newValues;

      if (value === "None") {
        newValues = ["None"];
      } else {
        const filteredValues = currentValues.filter((v) => v !== "None");

        newValues = filteredValues.includes(value)
          ? filteredValues.filter((v) => v !== value)
          : [...filteredValues, value];
      }

      const updatedValues = {
        ...prevAttributeValues,
        [key]: newValues,
      };
      validateForm(updatedValues);
      return updatedValues;
    });
  };

  const mergePatientData = (patientDetails, attributeValues) => {
    const mergedData = { ...attributeValues };

    Object.keys(patientDetails).forEach((key) => {
      if (patientDetails[key]) {
        mergedData[key] = patientDetails[key];
      }
    });

    return mergedData;
  };

  const validateForm = (updatedForm) => {
    let hasError = false;
    let errors = {};

    const allAttributes = attributes.flatMap((category) =>
      Object.values(category).flat()
    );

    allAttributes.forEach((attribute) => {
      if (attribute.required === "yes" && !updatedForm[attribute.key]) {
        hasError = true;
        errors[attribute.key] = true;
      } else {
        errors[attribute.key] = false;
      }
    });
    setformErrors(errors);
    setformErrorStatus(hasError);
    return !hasError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonClicked(true);
    if (validateForm(attribute_values)) {
      const patient_info = mergePatientData(patientDetails, attribute_values);
      props.form_details && props.form_details(patient_info);
      navigate(`/${current_app}/report`);
    }
  };

  const render_attributes = (data) => {
    return data?.map((item, index) => {
      const [categoryKey, categoryAttributes] = Object.entries(item)[0];
      return (
        <div key={index}>
          <h2 className="CatagoryName">{categoryKey}</h2>
          <div className="InputForm" id={categoryKey.split(" ").join("_")}>
            {categoryAttributes.map((attribute, attrIndex) => (
              <div className="" key={attrIndex}>
                {renderAttributeInput(attribute)}
              </div>
            ))}
          </div>
        </div>
      );
    });
  };

  const toggleWarning = (id) => {
    const WarningBoxEl = document.getElementById(id);
    WarningBoxEl.style.display = "none";
  };

  const toggleWarning_checkbox = (id) => {
    const WarningBoxEl = document.getElementById(id);
    WarningBoxEl.style.display = "none";
  };

  const renderAttributeInput = (attribute) => {
    switch (attribute.type) {
      case "number":
        return (
          <div key={attribute.key}>
            <Input
              type={attribute.type}
              name={attribute.key}
              value={
                attribute.key === "bmi"
                  ? attribute_values["BMI"]
                  : attribute_values[attribute.key] || attribute.value
              }
              onChange={handleChange}
              readOnly={attribute.key === "BMI" || attribute.key === "bmi"}
              placeholder={attribute.key}
              units={attribute.units || null}
              id={`${attribute.key}_${attribute.name}`}
              label={attribute.name}
              ranges={attribute.ranges}
              description={attribute.description}
              required={attribute.required}
              formErrors={formErrors}
              patient_required={attribute.required}
              buttonClicked={buttonClicked}
              img={attribute.img}
              color={props.color}
              secondaryColor={props.secondaryColor}
            />
          </div>
        );
      case "select":
        return (
          <>
            <Input
              name={attribute.key}
              placeholder={attribute.name}
              type={attribute.type}
              options={attribute.options || []}
              value={attribute_values[attribute.key] || attribute.value}
              onChange={handleMainOptionChange}
              className=""
              unit={attribute.units || null}
              id={`${attribute.key}_${attribute.name}`}
              description={attribute.description}
              label={attribute.name}
              required={attribute.required}
              formErrors={formErrors}
              patient_required={attribute.required}
              buttonClicked={buttonClicked}
              img={attribute.img}
              color={props.color}
              secondaryColor={props.secondaryColor}
            />

            {attribute.options.map(
              (option) =>
                attribute_values[attribute.key] === option.name &&
                option.sub_options && (
                  <div key={option.name} className="sub-option-container">
                    {option.sub_options.map((subOption) => (
                      <div key={subOption.value} className="subOptionsBox">
                        <Input
                          type={subOption.type}
                          name={subOption.key}
                          value={subOption.value}
                          unit={attribute.units || null}
                          options={option.sub_options}
                          id={`${subOption.key}_${subOption.name}`}
                          onChange={
                            subOption.type === "checkbox"
                              ? handleCheckboxChange
                              : handleChange
                          }
                          placeholder={subOption.name}
                          label={subOption.name}
                          patient_required={attribute.required}
                          formErrors={formErrors}
                          buttonClicked={buttonClicked}
                          color={props.color}
                          secondaryColor={props.secondaryColor}
                          ischeckbox={
                            attribute_values[subOption.key] &&
                            attribute_values[subOption.key].includes(
                              subOption.value
                            )
                          }
                        />
                        {subOption.type === "checkbox" &&
                          attribute_values[subOption.key].includes(
                            subOption.value
                          ) &&
                          subOption.sub_options && (
                            <div className="sub-option-container">
                              {subOption.sub_options.map((subSubOption) => (
                                <div
                                  key={subSubOption.value}
                                  className="sub-option-container"
                                >
                                  <Input
                                    type={subSubOption.type}
                                    name={subSubOption.key}
                                    unit={attribute.units || null}
                                    value={subSubOption.value}
                                    id={`${subSubOption.key}_${subSubOption.name}`}
                                    options={subOption.sub_options}
                                    onChange={
                                      subSubOption.type === "checkbox"
                                        ? handleCheckboxChange
                                        : handleChange
                                    }
                                    placeholder={subSubOption.name}
                                    label={subSubOption.name}
                                    patient_required={attribute.required}
                                    formErrors={formErrors}
                                    buttonClicked={buttonClicked}
                                    color={props.color}
                                    secondaryColor={props.secondaryColor}
                                    ischeckbox={
                                      attribute_values[subSubOption.key] &&
                                      attribute_values[
                                        subSubOption.key
                                      ].includes(subSubOption.value)
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )
            )}
          </>
        );
      case "checkbox":
        return (
          <div>
            <h4 className="TitleAttributePage">
              {attribute.required === "yes" && (
                <span>
                  <img
                    className="star-icon"
                    src="asterisk.svg"
                    alt="star.svg"
                  />
                </span>
              )}
              {attribute.name}
              {attribute.description && (
                <div className="definition-label-container">
                  <button
                    type="button"
                    onClick={() => toggleSidebar(attribute.name)}
                    className="description-bar"
                  >
                    i
                  </button>
                  {activeId === attribute.name && (
                    <SidebarOverlay
                      id={attribute.name}
                      description={attribute.description}
                      activeId={activeId}
                      name={attribute.name}
                      setActiveId={setActiveId}
                      toggleSidebar={toggleSidebar}
                    />
                  )}
                </div>
              )}
            </h4>

            {attribute.options &&
              attribute.options.map((option, index) => (
                <div key={index} className="checkbox-children">
                  <Input
                    type={option.type}
                    unit={attribute.units || null}
                    name={attribute.key}
                    value={option.value}
                    onChange={handleCheckboxChange}
                    placeholder={option.name}
                    label={attribute.name}
                    id={`${attribute.key}_${option.value}`}
                    formErrors={formErrors}
                    buttonClicked={buttonClicked}
                    color={props.color}
                    secondaryColor={props.secondaryColor}
                    attribute_values={attribute_values}
                    ischeckbox={
                      attribute_values[attribute.key] &&
                      attribute_values[attribute.key].includes(option.value)
                    }
                  />

                  {attribute_values[attribute.key].includes(option.name) &&
                    option.alert && (
                      <div
                        className="popup-main-container"
                        id={`${attribute.key}_${option.key}`}
                      >
                        <div className="warring-popup-container">
                          <h1 className="warring-popup-content-heading">
                            <img
                              className="warning-icon"
                              src="./warning.png"
                              alt="warning.png"
                            />
                            Warning..!
                          </h1>
                          <p className="popup-text-highlight-text">
                            {option.alert.msg}
                          </p>
                          <button
                            className="button warring-button"
                            type="button"
                            onClick={() =>
                              toggleWarning_checkbox(
                                `${attribute.key}_${option.key}`
                              )
                            }
                          >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    )}
                  {attribute_values[attribute.key].includes(option.value) &&
                    !attribute_values[attribute.key].includes("None") &&
                    option.sub_options &&
                    option.sub_options.map((subOption, subIndex) => (
                      <div key={subIndex}>
                        <Input
                          type={subOption.type}
                          name={subOption.key}
                          value={subOption.value}
                          options={subOption.sub_options}
                          id={`${subOption.key}_${subOption.name}`}
                          onChange={
                            subOption.type === "checkbox"
                              ? handleCheckboxChange
                              : handleChange
                          }
                          placeholder={subOption.name}
                          unit={attribute.units || null}
                          label={subOption.name}
                          formErrors={formErrors}
                          buttonClicked={buttonClicked}
                          color={props.color}
                          secondaryColor={props.secondaryColor}
                          ischeckbox={
                            attribute_values[subOption.key] &&
                            attribute_values[subOption.key].includes(
                              subOption.value
                            )
                          }
                        />
                      </div>
                    ))}
                </div>
              ))}
          </div>
        );
      case "radio":
        return (
          <div>
            <h4 className="TitleAttributePage">
              {attribute.required === "yes" && (
                <span>
                  <img
                    className="star-icon"
                    src="asterisk.svg"
                    alt="star.svg"
                  />
                </span>
              )}
              {attribute.name}
              {attribute.description && (
                <div className="description-info-button-container">
                  <button
                    type="button"
                    onClick={() => toggleSidebar(attribute.name)}
                    className="description-bar"
                  >
                    i
                  </button>
                  {activeId === attribute.name && (
                    <SidebarOverlay
                      id={attribute.name}
                      description={attribute.description}
                      activeId={activeId}
                      name={attribute.name}
                      setActiveId={setActiveId}
                      toggleSidebar={toggleSidebar}
                    />
                  )}
                </div>
              )}
            </h4>
            <div className="inputbox" id="boxForRadioButtonInAttributesPage">
              {attribute.options.map((option) => (
                <div key={option.name} className="anRadioOption">
                  {selectedOption[attribute.key] === option.name &&
                    option.alert && (
                      <div
                        className="popup-main-container"
                        id={`${attribute.key}_${option.key}`}
                      >
                        <div className="warring-popup-container">
                          <h1 className="warring-popup-content-heading">
                            <img
                              className="warning-icon"
                              src="./warning.png"
                              alt="warning.png"
                            />
                            Warning..!
                          </h1>
                          <p className="popup-text-highlight-text">
                            {option.alert.msg}
                          </p>
                          <button
                            className="button warring-button"
                            type="button"
                            onClick={() =>
                              toggleWarning(`${attribute.key}_${option.key}`)
                            }
                          >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    )}
                  <div className="sub-option-radio">
                    <input
                      id={`${attribute.key}_${option.name}`}
                      type="radio"
                      name={attribute.key}
                      className="radio-button"
                      value={option.name}
                      checked={attribute_values[attribute.key] === option.name}
                      onChange={() =>
                        handleMainOptionChange(attribute.key, option.name)
                      }
                    />
                    <span className="c-radio"></span>
                  </div>
                  <label htmlFor={`${attribute.key}_${option.name}`}>
                    {option.name}
                  </label>
                </div>
              ))}
            </div>
            {attribute.options.map(
              (option) =>
                selectedOption[attribute.key] === option.name &&
                option.sub_options && (
                  <div key={option.name} className="inputbox">
                    {option.sub_options.map((subOption) => (
                      <div
                        key={subOption.value}
                        className="sub-option-of-radio-button"
                      >
                        {subOption.type !== "radio" ? (
                          <Input
                            id={`${option.key}_${subOption.value}`}
                            name={subOption.key}
                            unit={attribute.units || null}
                            value={subOption.type!=="number"?subOption.value:attribute_values[subOption.name]}
                            options={subOption.sub_options}
                            onChange={
                              subOption.type === "checkbox"
                                ? handleCheckboxChange
                                : handleChange
                            }
                            placeholder={subOption.name}
                            label={subOption.name}
                            patient_required={attribute.required}
                            formErrors={formErrors}
                            buttonClicked={buttonClicked}
                            color={props.color}
                            secondaryColor={props.secondaryColor}
                            ischeckbox={
                              attribute_values[subOption.key] &&
                              attribute_values[subOption.key].includes(
                                subOption.value
                              )
                            }
                          />
                        ) : (
                          <div className="sub-option-radio">
                            <input
                              type="radio"
                              className="radio-button"
                              id={`${option.key}_${subOption.value}`}
                              name={`${attribute.key}_sub`}
                              value={subOption.value}
                              checked={
                                attribute_values[`${attribute.key}_sub`] ===
                                subOption.value
                              }
                              onChange={() =>
                                handleChange(
                                  `${attribute.key}_sub`,
                                  subOption.value
                                )
                              }
                            />
                            <span className="c-radio"></span>
                            <label
                              htmlFor={`${option.key}_${subOption.value}`}
                              className="radio-button-suboption-label"
                            >
                              {subOption.name}
                            </label>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )
            )}
            {formErrors[attribute.key] && buttonClicked && (
              <p className="form-validation-error-text">
                Please enter {attribute.name}
              </p>
            )}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <OuterFrame
      hightlight="2"
      appName={fields.landing_page[0].heading}
      color={props.color}
      secondaryColor={props.secondaryColor}
      surface_color={props.surface_color}
      surface_highlight_color={props.surface_highlight_color}
      surface_hover_color={props.surface_hover_color}
      button_hover_color={props.button_hover_color}
      component={
        loaderstatus ? (
          <div className="loader-container">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div id="contentContainer">
            <form onSubmit={handleSubmit} className="ActualForm">
              <div className="InputFormInAttributePage">
                {render_attributes(attributes)}
              </div>
              {formErrorStatus && buttonClicked && (
                <p className="form-validation-error-text">
                  Please enter all required Fields
                </p>
              )}
              <div className="generate-button-div">
                <button
                  id="generatePdfButton"
                  type="submit"
                  style={{
                    backgroundColor: buttonHover
                      ? "rgb(123, 104, 171)"
                      : "rgb(103, 84, 150)",
                  }}
                  onMouseEnter={() => setButtonHover(true)}
                  onMouseLeave={() => setButtonHover(false)}
                >
                  Generate Report
                </button>
              </div>
            </form>
          </div>
        )
      }
    />
  );
};
export default Attributes;
