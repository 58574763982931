import { openDB } from "idb";
import Cookies from "js-cookie";

export const initializeDB = async (projectName) => {
  if (!projectName) {
    throw new Error("Project name is required");
  }

  const db = await openDB("ImageStore", 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(projectName)) {
        db.createObjectStore(projectName, { keyPath: "id" });
      }
    },
  });

  return db;
};

// Generic function to fetch and store project URLs dynamically
export const fetchImageUrls = async (urls, sas_token) => {
  const token = sas_token !== undefined ? sas_token : Cookies.get("sas_token");
  return urls.map((url) => `${url}?${token}`);
};

export const storeImageBlobInDB = async (projectName, id, blob, name) => {
  try {
    const db = await initializeDB(projectName);

    if (!db.objectStoreNames.contains(projectName)) {
      throw new Error(
        `Object store '${projectName}' does not exist in database.`
      );
    }

    const tx = db.transaction(projectName, "readwrite");
    const store = tx.objectStore(projectName);

    await store.put({ id, blob, name });
    await tx.done;
  } catch (error) {
    console.error(
      `Error storing image blob in IndexedDB for project: ${projectName}`,
      error
    );
  }
};

// Retrieve images from a project-specific store in IndexedDB
export const getImagesFromDB = async (projectName) => {
  if (!projectName) throw new Error("Project name is required");
  try {
    const db = await initializeDB(projectName);
    const tx = db.transaction(projectName, "readonly");
    const store = tx.objectStore(projectName);
    const allImages = await store.getAll();
    return allImages.map((image) => ({
      id: image.id,
      blobUrl: URL.createObjectURL(image.blob),
      name: image.name || "Unnamed Image",
    }));
  } catch (error) {
    return [];
  }
};

export const fetchAndStoreImages = async (
  projectName,
  urls,
  setImages,
  sas_token
) => {
  const appName = [
    "aicvd",
    "prediabetes",
    "aicopd",
    "liver",
    "aickd",
    "ears",
    "ews",
    "anesthesia",
    "cxr",
    "tb",
  ];

  const token = sas_token !== undefined ? sas_token : Cookies.get("sas_token");
  try {
    // Fetch all images in parallel
    const fetchPromises = urls.map((url, index) => {
      const fetchUrl = `${url}?${token}`;
      return fetch(fetchUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image at ${url}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const id =
            projectName === "home"
              ? `${projectName}-image-${appName[index]}`
              : `${projectName}-image-${index + 1}`;
          const name = `Image-${index + 1}`;
          return { id, blob, name, index }; // Return an object with image details
        })
        .catch((error) => {
          console.error(`Error fetching image ${url}:`, error.message);
          return null; // Return null for failed fetches
        });
    });

    // Wait for all fetches to complete
    const results = await Promise.all(fetchPromises);

    // Filter out any failed fetches (null values)
    const successfulImages = results.filter((result) => result !== null);

    // Store blobs in DB and prepare the image list
    const images = [];
    for (const { id, blob, name } of successfulImages) {
      await storeImageBlobInDB(projectName, id, blob, name);
      images.push({ id, blobUrl: URL.createObjectURL(blob), name });
      console.log(`Image fetched and stored: ${id}`);
    }

    // Update state
    setImages(images);
  } catch (error) {
    console.error("Error during image fetching and storing:", error.message);
  }
};

export const fetchAndStoreImagesAICVD = async (
  projectName,
  urls,
  sas_token
) => {
  const token = sas_token !== undefined ? sas_token : Cookies.get("sas_token");
  try {
    const fetchPromises = urls.map((url, index) => {
      const fetchUrl = `${url}?${token}`;
      return fetch(fetchUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch image at ${url}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const id = `${projectName}-image-${index + 1}`;
          const name = `Image-${index + 1}`;
          return { id, blob, name, index }; 
        })
        .catch((error) => {
          console.error(`Error fetching image ${url}:`, error.message);
          return null; 
        });
    });

    const results = await Promise.all(fetchPromises);
    const successfulImages = results.filter((result) => result !== null);
    const images = [];
    for (const { id, blob, name } of successfulImages) {
      await storeImageBlobInDB(projectName, id, blob, name);
      images.push({ id, blobUrl: URL.createObjectURL(blob), name });
      console.log(`Image fetched and stored: ${id}`);
    }
  } catch (error) {
    console.error("Error during image fetching and storing:", error.message);
  }
};
