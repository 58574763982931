import React from "react";
import "./index.css";

export default function TableOfContents(props) {
  const { onItemClick } = props;

  const handleItemClick = (item) => {
    onItemClick(item);
  };

  return (
    <ul id="TableOfContent" className="Table-of-Content">
      {props.titleNames?.map((item, i) => {
        return (
          <li
            style={{ cursor: "pointer" }}
            key={item + i}
            id={props.tableOfContent[i] === props.activeItem ? "activeItem" : ""}
            className="Table-Of-ContentItem"
            onClick={() => handleItemClick(props.tableOfContent[i])}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
}
