import React from "react";
import "./index.css";

const SidebarOverlay = ({
  id,
  description,
  activeId,
  name,
  setActiveId,
  toggleSidebar,
}) => {
  const renderPoints = (points) => {
    return points.map((point, index) => {
      if (typeof point === "string") {
        return (
          <li key={index} className="description-list-element">
            {point}
          </li>
        );
      } else if (typeof point === "object") {
        return (
          <li key={index} className="description-list-element">
            {Object.entries(point).map(([key, value]) => (
              <div key={key}>
                <li className="description-header">{key}</li>
                <ul className="nested-list">{renderPoints(value)}</ul>
              </div>
            ))}
          </li>
        );
      }
      return null;
    });
  };
  return (
    <div>
      <div key={id}>
        {activeId === id && (
          <div className={`sidebar open`}>
            <h2 className="description-name">{name}</h2>
            <div className="description-container">
              <ul className="description-list">
                {renderPoints(description.points)}
              </ul>
              {description.Source && (
                <p className="description-paragraph">
                  <strong className="description-header">Source: </strong>
                  {description.Source}
                </p>
              )}
              {description.link && (
                <p className="description-paragraph">
                  <strong className="description-header">Link: </strong>
                  <a
                    className="description-link"
                    href={description.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {description.link}
                  </a>
                </p>
              )}
            </div>
            <div className="description-close-button-container">
              <button
                onClick={() => toggleSidebar(id)}
                className="description-close-button"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      {activeId && (
        <div className="overlay" onClick={() => setActiveId(null)}></div>
      )}
    </div>
  );
};

export default SidebarOverlay;
