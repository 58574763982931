import React, { useState } from 'react';
import CheckboxInput from './CheckboxInput';

// JSON data
const hepatitisData = {
  "description": "",
  "key": "hepatitis",
  "name": "Does the patient have had previous history of following Infective Hepatitis?",
  "type": "checkbox",
  "options": [
    {
      "name": "HAV IgM",
      "value": "HAV IgM",
      "type": "checkbox",
      "description": "Positive for Hepatitis A virus",
      "key": "hav_igm",
      "alert": {
        "heading": "Warning",
        "msg": "This model is built on Non Infective - Non Alcoholic Fatty Liver Disease and hence it's interpretation and prediction accuracy may vary. Physician's discretion is advised"
      },
      "sub_options": [
        { "key": "hav_igm_no_years", "name": "NO.Of Years", "type": "number" }
      ]
    },
    // Other options follow...
    {
      "name": "None",
      "value": "None",
      "type": "checkbox",
      "key": "none"
    }
  ],
  "required": "yes"
};

const HepatitisCheckboxForm = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    if (option.value === "None") {
      setSelectedOptions([option.value]);
    } else {
      setSelectedOptions((prevSelected) => {
        if (prevSelected.includes(option.value)) {
          return prevSelected.filter((val) => val !== option.value);
        } else {
          return [...prevSelected.filter((val) => val !== "None"), option.value];
        }
      });
    }
  };

  return (
    <div>
      <h3>{hepatitisData.name}</h3>
      {hepatitisData.options.map((option) => (
        <div key={option.key}>
          <CheckboxInput
            option={option}
            isChecked={selectedOptions.includes(option.value)}
            onChange={handleCheckboxChange}
          />
          {selectedOptions.includes(option.value) && option.sub_options && (
            <div style={{ marginLeft: "20px" }}>
              {option.sub_options.map((sub) => (
                <div key={sub.key}>
                  <label>{sub.name}</label>
                  <input type={sub.type} name={sub.key} />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default HepatitisCheckboxForm;
